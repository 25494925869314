"use strict";
/**
 * Created by hanyongshan on 2021/11/23.
 */
import request from "@/utils/request";

export function appList(data: any) {
  return request({
    url: "/center/mobile/application/getAppList",
    method: "get",
    params: data,
  });
}
