import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e715add"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "blockbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item")!
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_panel_block = _resolveComponent("panel-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["workbench", _ctx.classlist]),
    onAnimationend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.animationend && _ctx.animationend(...args))),
    onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
    onTouchmove: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.touchMove && _ctx.touchMove(...args)))
  }, [
    _createVNode(_component_van_nav_bar, {
      placeholder: "",
      fixed: ""
    }, {
      title: _withCtx(() => [
        _createVNode(_component_van_dropdown_menu, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_dropdown_item, {
              "title-class": "dropdownmenutitle",
              modelValue: _ctx.currentProjectId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentProjectId) = $event)),
              options: _ctx.projectList,
              onChange: _ctx.changeSelectProject
            }, null, 8, ["modelValue", "options", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workBenchList, (item, index) => {
        return (_openBlock(), _createBlock(_component_panel_block, {
          "block-data": item,
          key: index
        }, null, 8, ["block-data"]))
      }), 128))
    ])
  ], 34))
}