
import { Options, Vue, Watch } from "vue-property-decorator";
import PanelBlock from "./panelblock.vue";
import * as api from "@/api/workbench";
@Options({
  name: "Workbench",
  components: {
    PanelBlock,
  },
})
export default class extends Vue {
  touch: any = {};
  currentProjectId = 0;
  selectProject: any = {};
  classlist: string[] = ["entry"];
  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    const index = sessionStorage.getItem("index");
    if (index) {
      if (index === "1") {
        this.classlist[0] = "entry";
      }
      if (index === "3") {
        this.classlist[0] = "rightentry";
      }
    }
  }

  @Watch("$store.state.project.projectinfo", { immediate: true })
  private projectinfoWatch(val: any) {
    this.selectProject = val;
    this.currentProjectId = val.projectId;
  }

  get projectList() {
    const list = this.$store.state.project.projectList;
    return list.map((item: any) => {
      return { text: item.projectName, value: item.projectId };
    });
  }
  touchStart(e: any) {
    const touch = e.touches[0];

    //点击时的位子
    this.touch.startX = touch.pageX;
    this.touch.startY = touch.pageY;
  }
  touchMove(e: any) {
    const touch = e.touches[0];
    //滑动位置和初始位置差
    const deltaX = touch.pageX - this.touch.startX;
    if (Math.abs(deltaX) > 100 && deltaX < 0) {
      this.onTouch("left");
    }
    if (Math.abs(deltaX) > 100 && deltaX > 0) {
      this.onTouch("right");
    }
  }
  mounted() {
    //把疫情防控的选项卡active设置为原始值
    this.$store.commit("project/set_Active", 0);
    this.appList();
  }
  appList(id = this.$store.state.project.projectinfo.projectId) {
    api
      .appList({
        projectId: id,
      })
      .then((res: any) => {
        if (res.code !== 0) {
          return this.$toast.fail(res.msg);
        }
        this.workBenchList = res.data;
      });
  }
  workBenchList = [
    {
      title: "人员",
      subList: [
        {
          subTitle: "人员管理",
          image: require("../../../assets/workbench/rygl.png"),
          url: "/app/personmanage",
        },
        {
          subTitle: "安全巡检",
          image: require("../../../assets/workbench/rygl.png"),
          url: "/app/anquanxunjian",
        },
        {
          subTitle: "人员出入",
          image: require("../../../assets/workbench/rygl.png"),
          url: "/app/personaccess",
        },
        {
          subTitle: "考勤汇总",
          image: require("../../../assets/workbench/rygl.png"),
          url: "/app/duty",
        },
        {
          subTitle: "人员档案",
          image: require("../../../assets/workbench/rygl.png"),
          url: "/app/personrecord",
        },
      ],
    },
    {
      title: "机器",
      subList: [
        {
          subTitle: "吊塔管理",
          image: require("../../../assets/workbench/dt.png"),
        },
        {
          subTitle: "设备管理",
          image: require("../../../assets/workbench/sb.png"),
        },
        {
          subTitle: "视频监控",
          image: require("../../../assets/workbench/sp.png"),
        },
        {
          subTitle: "深基坑",
          image: require("../../../assets/workbench/sjk.png"),
        },
      ],
    },
    {
      title: "法",
      subList: [
        {
          subTitle: "事件管理",
          image: require("../../../assets/workbench/sj.png"),
        },
        {
          subTitle: "进度管理",
          image: require("../../../assets/workbench/jd.png"),
        },
        {
          subTitle: "质量管理",
          image: require("../../../assets/workbench/zl.png"),
        },
        {
          subTitle: "安全管理",
          image: require("../../../assets/workbench/aq.png"),
        },
      ],
    },
    {
      title: "环境",
      subList: [
        {
          subTitle: "环境监测",
          image: require("../../../assets/workbench/hjjc.png"),
          url: "/app/environmentaltest",
        },
      ],
    },
  ];
  onTouch(e: string) {
    if (e === "left") {
      this.classlist[0] = "leftleave";
    } else if (e === "right") {
      this.classlist[0] = "rightleave";
    }
    sessionStorage.setItem("index", "2");
  }
  animationend() {
    if (this.classlist[0] === "rightleave") {
      this.$router.push("/system/project");
    } else if (this.classlist[0] === "leftleave") {
      this.$router.push("/system/myself");
    }
  }
  changeSelectProject() {
    const project = this.$store.state.project.projectList.find((item: any) => {
      return item.projectId === this.currentProjectId;
    });
    if (project !== null) {
      this.appList(project.projectId);
      this.$store.commit("project/SET_SELECTPROJECT", project);
    }
  }
}
